import i18nConfigs from "../../i18n/config.json"

export type Lang =
  | "en"

export interface Language {
  code: Lang
  hrefLang: string
  name: string
  localName: string
  dateFormat: string
}

export type Languages = {
  [lang in Lang]: Language
}

export const defaultLanguage: Lang = "en"

// same data as in the `config.json` but indexed by language code
const languages: Languages = i18nConfigs.reduce((result, config) => {
  return { ...result, [config.code]: config }
}, {} as Languages)

const buildLangs = (process.env.GATSBY_BUILD_LANGS || "")
  .split(",")
  .filter(Boolean)

// will take the same shape as `languages`. Only thing we are doing
// here is filtering the desired langs to be built
export const languageMetadata: {
  [lang: string]: Language
} = Object.fromEntries(
  Object.entries(languages).filter(([lang]) => {
    // BUILD_LANGS === empty means to build all the langs
    if (!buildLangs.length) {
      return true
    }

    return buildLangs.includes(lang)
  })
)

export const supportedLanguages = Object.keys(languageMetadata) as Array<Lang>

export const isLang = (lang: string): lang is Lang =>
  supportedLanguages.includes(lang as Lang)

export default languages
