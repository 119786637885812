import * as React from "react"
import MainNav from "./MainNav"
import { IntlProvider } from "react-intl"
import { Flex, SimpleGrid, useColorModeValue } from "@chakra-ui/react"
import { Context } from "../types"
import { LocaleProvider } from "gatsby-theme-i18n"
import { ThemeProvider } from "@emotion/react"
import { lightTheme, darkTheme } from "../theme"
import Footer from "./Footer"

export interface IProps {
  children?: React.ReactNode
  path: string
  pageContext: Context
}

const Layout: React.FC<IProps> = ({ pageContext, path, children }) => {
  // TODO: tmp - for backward compatibility with old theme
  const theme = useColorModeValue(lightTheme, darkTheme)
  const locale = pageContext.locale
  const messages = require(`../intl/${locale}.json`)

  return (
    <LocaleProvider pageContext={pageContext}>
      {/* our current react-intl types does not support react 18 */}
      {/* TODO: once we upgrade react-intl to v6, remove this ts-ignore */}
      {/* @ts-ignore */}
      <IntlProvider locale={locale!} key={locale} messages={messages}>
        <ThemeProvider theme={theme}>
          <Flex
            position="relative"
            margin="0px auto"
            minHeight="100vh"
            flexFlow="column"
            maxW={{
              lg: theme.variables.maxPageWidth,
            }}
          >
            <MainNav />
            <Flex flexDirection="column" width="100%">
              <Flex
                bgColor="ednBackground"
                justifyContent="space-around"
                flexDirection="column"
                alignItems="flex-start"
                overflow="visible"
                width="100%"
                flexGrow="1"
              >
                {children}
              </Flex>
            </Flex>

            <Footer />
          </Flex>
        </ThemeProvider>
      </IntlProvider>
    </LocaleProvider>
  )
}

export default Layout
