import React from "react"
import { Icon, Link as ChakraLink, LinkProps } from "@chakra-ui/react"
import { LocalizedLink as IntlLink } from "gatsby-theme-i18n"

import { BsQuestionSquareFill } from "react-icons/bs"

import { Lang } from "../utils/languages"
import * as url from "../utils/url"

export interface IBaseProps {
  href?: string
  language?: Lang
  hideArrow?: boolean
  isPartiallyActive?: boolean
  activeStyle?: object
}

export interface IProps extends IBaseProps, LinkProps {}

/**
 * Link wrapper which handles:
 *
 * - Hashed links
 * e.g. <Link href="/page-2/#specific-section">
 *
 * - External links
 * e.g. <Link href="https://example.com/">
 *
 * - Intl links
 * e.g. <Link href="/page-2/" language="de">
 */
const Link: React.FC<IProps> = ({
  href,
  language,
  children,
  hideArrow = false,
  isPartiallyActive = true,
  activeStyle = null,
  ...restProps
}) => {
  const to = href + ""

  const isExternal = url.isExternal(to)
  const isGlossary = url.isGlossary(to)

  const commonProps = {
    ...restProps,
  }

  // Opens in separate window.
  if (isExternal) {
    return (
      <ChakraLink
        href={to}
        isExternal
        _after={{
          content: !hideArrow ? '"↗"' : undefined,
          ml: 0.5,
          mr: 1.5,
        }}
        _hover={{
          transition: "transform 0.1s",
          transform: "scale(1.2)",
        }}
        {...commonProps}
      >
        {children}
      </ChakraLink>
    )
  }

  // Use `gatsby-theme-i18n` Link (which prepends lang path)
  return (
    <ChakraLink
      to={to}
      as={IntlLink}
      language={language}
      partiallyActive={isPartiallyActive}
      whiteSpace={isGlossary ? "nowrap" : "normal"}
      {...commonProps}
    >
      {children}
      {isGlossary && (
        <Icon
          as={BsQuestionSquareFill}
          aria-label="See definition"
          fontSize="12px"
          margin="0 0.25rem 0 0.35rem"
          _hover={{
            transition: "transform 0.1s",
            transform: "scale(1.2)",
          }}
        />
      )}
    </ChakraLink>
  )
}

export default Link
