import { Box } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React from "react"
import Translation from "./Translation"

const BoxContainer = styled(Box)`
  color: ${(props) => props.theme.colors.text};
  border-top: 1px solid ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`

export interface IProps {}

const Footer: React.FC<IProps> = () => {
  return (
    <BoxContainer as="footer" p="1rem 2rem">
      <Translation id="copyright" />
    </BoxContainer>
  )
}

export default Footer
